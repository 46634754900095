type Omit = <T extends object, TOmit extends Readonly<Array<keyof T>>>(
  obj: T,
  keys: TOmit
) => {
  [TKey in Exclude<keyof T, TOmit[number]>]: T[TKey]
}

const omit: Omit = (obj, keys) => {
  const clone = {} as {
    [K in keyof typeof obj]: (typeof obj)[K]
  }
  let key: keyof typeof obj
  for (key in obj) {
    if (!keys.includes(key)) {
      clone[key] = obj[key]
    }
  }
  return clone
}

export { omit }
