import { Breakpoint, breakpoints } from '@/camo/utils/theme'
import { type Responsive } from 'baseui/theme'
import { createBreakpoint } from 'react-use'

const extendedBreakpoints: Record<Breakpoint, number> = {
  xsmall: 0,
  small: breakpoints[Breakpoint.XS],
  medium: breakpoints[Breakpoint.S],
  large: breakpoints[Breakpoint.M],
  xlarge: breakpoints[Breakpoint.L],
}

const useBreakpoint = () => {
  const breakpoint = createBreakpoint(extendedBreakpoints)() as Breakpoint

  const getResponsiveValue = <T>(values: Responsive<T>) => {
    if (!Array.isArray(values)) {
      return values
    }

    switch (breakpoint) {
      case Breakpoint.XS:
        return values[0]
      case Breakpoint.S:
        return values[1] ?? values[0]
      case Breakpoint.M:
        return values[2] ?? values[1] ?? values[0]
      case Breakpoint.L:
        return values[3] ?? values[2] ?? values[1] ?? values[0]
      case Breakpoint.XL:
        return values[4] ?? values[3] ?? values[2] ?? values[1] ?? values[0]
    }
  }

  const isMobile = breakpoint === Breakpoint.XS || breakpoint === Breakpoint.S
  const isTablet = breakpoint === Breakpoint.M
  const isDesktop = !isMobile && !isTablet

  return {
    breakpoint,
    isMobile,
    isTablet,
    isDesktop,
    getResponsiveValue,
  }
}

export { extendedBreakpoints, useBreakpoint }
