import { Loading as BaseLoading, type LoadingProps as BaseLoadingProps } from '@capdesk/camo'
import { useTranslation } from 'react-i18next'

interface LoadingProps extends Omit<BaseLoadingProps, 'text' | 'hideText'> {
  hideText?: boolean
  text?: string
}

const Loading = (props: LoadingProps) => {
  const { t } = useTranslation('common')

  if (props.hideText) {
    return <BaseLoading {...props} hideText />
  }

  return <BaseLoading {...props} text={props.text ?? t('loading')} />
}

export { Loading }
