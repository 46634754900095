import { useStyletron, Block } from '@capdesk/camo'
import { useTranslation } from 'react-i18next'
import { type ApiError } from 'src/hooks/use-api'

interface ApiErrorsProps {
  apiError: ApiError
  fallbackMessage?: string
}

const ApiErrors = ({ apiError, fallbackMessage }: ApiErrorsProps) => {
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const Errors = ({ errors = [] }: { errors: string[] }) => {
    return (
      <>
        {errors.map((error) => (
          <li key={error}>
            {
              document.createTextNode(error || (fallbackMessage ?? t('validation:form.default_error_message')))
                .textContent
            }
          </li>
        ))}
      </>
    )
  }

  return (
    <Block>
      {apiError.documents_errors && (
        <ul className={css({ paddingLeft: theme.sizing.scale600 })}>
          {apiError.documents_errors?.map(({ id, errors }) =>
            errors.map(({ errors }, index) => <Errors key={`${id}-${index}`} errors={errors} />)
          )}
        </ul>
      )}
      <ul className={css({ paddingLeft: theme.sizing.scale600 })}>
        {apiError.errors?.map(({ attribute_translated: attributeTranslated, errors }, index) => {
          const key = `${attributeTranslated}-${index}`
          const hasTitle = Boolean(attributeTranslated && attributeTranslated !== 'Base')

          if (!hasTitle) {
            return <Errors key={key} errors={errors} />
          }

          return (
            <li className={css({ marginBottom: theme.sizing.scale600 })} key={key}>
              <>
                {attributeTranslated}
                <ul>
                  <Errors errors={errors} />
                </ul>
              </>
            </li>
          )
        })}
      </ul>
    </Block>
  )
}

export { ApiErrors }
