import { convertDateToUTC } from './convert-date-to-utc'
import { FORMAT_LOCALE } from './locale'

const formatDateMonthYear = (value: string | Date) => {
  return new Intl.DateTimeFormat(FORMAT_LOCALE, {
    month: 'short',
    year: 'numeric',
  }).format(convertDateToUTC(new Date(value)))
}

export { formatDateMonthYear }
