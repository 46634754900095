import { type ReactChild, type ReactFragment, type ReactPortal, type ReactElement, Fragment } from 'react'

type RemoveWrappingFragmentReturnType =
  | ReactChild
  | ReactChild[]
  | ReactFragment
  | ReactFragment[]
  | ReactFragment
  | ReactPortal[]
type RemoveWrappingFragment = (child: ReactChild | ReactFragment | ReactPortal) => RemoveWrappingFragmentReturnType

/**
 * If the children are Fragments, so elements that don't get rendered at all, instead find their
 * children and flatten those into a single array of elements
 */
const removeWrappingFragment: RemoveWrappingFragment = (child) => {
  const castedChild = child as ReactElement
  if (castedChild?.type === Fragment) {
    const children: ReactChild | ReactChild[] = castedChild?.props?.children
    return Array.isArray(children) ? children.map(removeWrappingFragment).flat() : children
  }

  return castedChild
}

export { removeWrappingFragment }
