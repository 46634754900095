import { showToast, type SupportedToastKinds } from '@/camo/molecules/Toast'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

interface ToastType {
  kind?: SupportedToastKinds
  msg?: string
  timestamp?: number
}

const WINDOW_FOR_SHOWING_TOAST = 6000
const now = new Date().getTime()

const useLocalStorageToasts = (storageKey: string) => {
  const [toast, , removeToast] = useLocalStorage<ToastType>(storageKey)

  useEffect(() => {
    if (toast?.timestamp) {
      if (now - toast?.timestamp < WINDOW_FOR_SHOWING_TOAST) {
        showToast(toast?.msg ?? '', toast?.kind)
      }
      removeToast()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { useLocalStorageToasts }
