import { convertDateToUTC } from '@capdesk/utils'
import { format } from 'date-fns'

const MILLISECONDS_IN_ONE_DAY = 86400000

const removeHour = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

const getMsDifference = (firstDate: Date, secondDate: Date) => {
  return secondDate.getTime() - firstDate.getTime()
}

const toApiDateString = (date: Date | string) => {
  return format(convertDateToUTC(new Date(date)), 'yyy-MM-dd')
}

/**
 * Returns days left until date. Will return a negative number
 * if the current date is past the specified date
 */
const daysUntilDate = (date?: Date | string | null) => {
  if (!date) {
    return
  }
  const now = convertDateToUTC(new Date())
  const dateAtEndOfDay = convertDateToUTC(new Date(date))
  dateAtEndOfDay.setHours(23, 59, 59, 999)

  const msDiff = getMsDifference(now, dateAtEndOfDay)

  return Math.floor(msDiff / MILLISECONDS_IN_ONE_DAY)
}

/**
 * Returns whether the given date is today (same day, month and year).
 */
const isToday = (date: Date) => {
  const today = new Date()

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  )
}

const getDateWithoutTime = (date?: Date) => {
  const currentDate = date ?? new Date()

  return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
}

export {
  removeHour,
  getMsDifference,
  toApiDateString,
  daysUntilDate,
  isToday,
  getDateWithoutTime,
  MILLISECONDS_IN_ONE_DAY,
}
