import type React from 'react'
import { isValidElement } from 'react'

const getNodeText = (node: React.ReactNode): string | undefined => {
  if (['string', 'number'].includes(typeof node)) {
    return `${node}`
  }
  if (node instanceof Array) {
    return node.map(getNodeText).join('')
  }

  if (isValidElement(node)) {
    return getNodeText(node.props.children)
  }
}

const truncateString = (stringNode: React.ReactNode) => {
  if (!stringNode) {
    return null
  }

  const fullString = getNodeText(stringNode)

  // If we can't parse the element text
  if (!fullString) {
    return stringNode
  }

  // We split the string on the following three cases:
  // 1. Whitespace ( )
  // 2. Parenthesis ( ( & ) )
  // 3. Hyphens and underscores (- & _)
  // The regex also ensure that we save the splitter at the end of each splitted word. When every word has been
  // transformed, we join them together again.
  return fullString
    .split(/(?=[()\-_\s])/)
    .map((word: string) => {
      // We try to extract the splitter. It should be the first char of the word of one of the three cases above.
      // If this is the first word of the string, then we have no splitter at the end and therefore the splitter
      // might be nil.
      const splitter = word.match(/[()\-_\s]/)
      if (splitter !== null) {
        word = word.replace(splitter[0], '')
      }

      // With the splitter out of the picture, we search for any numbers at the end of the word. If we find any,
      // we extract them for now.
      const endDigits = word.match(/\d{0,100}$/)
      if (endDigits !== null) {
        word = word.replace(endDigits[0], '')
      }

      // With numbers out of the way, we cut the word down to max three chars. If the word is smaller than three
      // chars, then the cut will also work as expected. We also uppercase the word
      word = word.substring(0, 3).toUpperCase()

      // Now, that we have the word in three chars we re-add the digits and splitter, if they were extracted.
      if (splitter !== null) {
        word = `${splitter}${word}`
      }
      if (endDigits !== null) {
        word = `${word}${endDigits}`
      }

      return word
    })
    .join('')
}

export { getNodeText, truncateString }
