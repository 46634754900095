import { type ButtonKindUnion } from '@/camo/atoms/Button/types'
import { capitalizeString, expandShorthand } from '@/camo/utils'
import { type ThemeT } from '@/camo/utils/theme'
import { KIND as BaseButtonKind } from 'baseui/button'
import { type StyleObject } from 'styletron-react'
import { ButtonKind, ButtonSize } from './enums'

const buttonKindMap = {
  [ButtonKind.PRIMARY]: BaseButtonKind.primary,
  [ButtonKind.SECONDARY]: BaseButtonKind.secondary,
}

interface UseExtendedButtonKindArgs {
  kind: ButtonKindUnion
  baseStyle?: StyleObject
  size?: ButtonSize

  /**
   * Need to style the initial state of small icon buttons differently,
   * because reasons.
   */
  isSmallIconButton?: boolean
}

type CaptalizedExtendedKind = 'Primary' | 'Secondary' | 'Positive' | 'Negative' | 'Minimal'

const getUniversalButtonStyle = ({
  activeColor,
  nonActiveColor,
  hoverColor,
}: {
  activeColor: string
  nonActiveColor: string
  hoverColor: string
}) => {
  return {
    ':not(:active) *': {
      fill: nonActiveColor,
      color: nonActiveColor,
    },
    ':active *': {
      fill: activeColor,
      color: activeColor,
    },
    ':not(:active):hover *': {
      color: hoverColor,
      fill: hoverColor,
    },
  }
}

const useExtendedButtonKind = ({
  kind,
  baseStyle = {},
  isSmallIconButton = false,
  size,
}: UseExtendedButtonKindArgs) => {
  const isBaseKind = Object.keys(BaseButtonKind).includes(kind)
  const baseKind = isBaseKind
    ? buttonKindMap[kind as 'primary' | 'secondary']
    : kind === ButtonKind.MINIMAL
      ? 'secondary'
      : 'primary'

  const style = ({ $theme, $disabled }: { $theme: ThemeT; $disabled: boolean }) => {
    if ($disabled) {
      return {
        ...baseStyle,
        ':not(:active)': {
          color: $theme.colors.buttonDisabledText,
          fill: $theme.colors.buttonDisabledText,
        },
        ':not(:active) *': {
          color: $theme.colors.buttonDisabledText,
          fill: $theme.colors.buttonDisabledText,
        },
        ':disabled': {
          ':active': {
            color: $theme.colors.buttonDisabledText,
            fill: $theme.colors.buttonDisabledText,
          },
          ':active *': {
            color: $theme.colors.buttonDisabledText,
            fill: $theme.colors.buttonDisabledText,
          },
          ':hover': {
            ...expandShorthand({
              backgroundColor: `${kind === ButtonKind.MINIMAL ? 'unset' : $theme.colors.buttonDisabledFill}`,
              border: `none`,
            }),
          },
          backgroundColor: `${kind === ButtonKind.MINIMAL ? 'unset' : $theme.colors.buttonDisabledFill}`,
          ...expandShorthand({ border: `none` }),
        },
      }
    }
    const extendedKind = capitalizeString(kind) as CaptalizedExtendedKind

    const nonActiveBackgroundColor = $theme.colors[`button${extendedKind}Fill`]
    const nonActiveTextColor = $theme.colors[`button${extendedKind}Text`]
    const nonActiveBorderColor =
      kind === ButtonKind.SECONDARY
        ? isSmallIconButton
          ? $theme.colors.primary
          : $theme.colors.grayLight
        : 'transparent'

    const activeBackgroundColor = $theme.colors[`button${extendedKind}Active`]
    const activeTextColor =
      kind === ButtonKind.MINIMAL ? $theme.colors[`button${extendedKind}Text`] : $theme.colors.buttonPrimaryText
    const activeBorderColor = 'transparent'

    const isNotSecondaryOrMinimalButton = ![ButtonKind.SECONDARY, ButtonKind.MINIMAL].includes(kind as ButtonKind)
    const isSmallIconSecondaryButton = isSmallIconButton && kind === ButtonKind.SECONDARY
    const hasWhiteHoverTextColor = isNotSecondaryOrMinimalButton || isSmallIconSecondaryButton

    const hoverTextColor = hasWhiteHoverTextColor ? $theme.colors.white : $theme.colors.primary
    const hoverBackgroundColor =
      kind === ButtonKind.SECONDARY && isSmallIconButton
        ? $theme.colors.buttonPrimaryHover
        : $theme.colors[`button${extendedKind}Hover`]
    const hoverBorderColor = kind === ButtonKind.SECONDARY && !isSmallIconButton ? $theme.colors.primary : 'transparent'

    const extendedKindStyle = {
      backgroundColor: nonActiveBackgroundColor,
      color: nonActiveTextColor,
      ...expandShorthand({
        border: `1px solid ${nonActiveBorderColor}`,
      }),
      ':active': {
        backgroundColor: activeBackgroundColor,
        color: activeTextColor,
        ...expandShorthand({
          border: `1px solid ${activeBorderColor}`,
        }),
      },
      ':not(:active):hover': {
        backgroundColor: hoverBackgroundColor,
        color: hoverTextColor,
        border: `1px solid ${hoverBorderColor}`,
      },

      // Need these in order to properly color all button children (text + icons)
      ...getUniversalButtonStyle({
        activeColor: activeTextColor,
        nonActiveColor: nonActiveTextColor,
        hoverColor: hoverTextColor,
      }),
    }

    return {
      ...baseStyle,
      ...extendedKindStyle,
    }
  }

  return {
    overrides: {
      BaseButton: { style },
      LoadingSpinner: {
        style: {
          height: size === ButtonSize.SMALL ? '14px' : '20px',
          width: size === ButtonSize.SMALL ? '14px' : '20px',
          ...expandShorthand({ borderWidth: size === ButtonSize.SMALL ? '2px' : '3px' }),
        },
      },
    },
    baseKind: baseKind as 'primary' | 'secondary',
  }
}

export { useExtendedButtonKind, getUniversalButtonStyle }
