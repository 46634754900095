import { languageSets, languageSetsJoin } from 'src/i18n/terminology/match-sets'
import { replaceMatchSetSafari } from 'src/i18n/terminology/replace-match-set-safari'
import {
  type ExercisePriceNames,
  type PhantomShareNames,
  type TerminologySettingType,
  type TerminologySettings,
  TerminologySettingDefaults,
} from 'src/types/api/generated'
import { entries } from 'src/utilities/entries'

const IS_NEGATIVE_LOOKBEHIND_SUPPORTED = (() => {
  try {
    return Boolean('true'.replace(new RegExp('(?<!test)', 'g'), 'true'))
  } catch (e) {
    return false
  }
})()

const replaceMatchSet = ({
  translatedValue,
  matchSet,
  matchSetJoin,
}: {
  translatedValue: string
  matchSet: Index<string>
  matchSetJoin: string
}) => {
  const regex = new RegExp(`(?<!\\[exclude\\])\\b(${matchSetJoin})\\b(?!\\[/exclude\\])`, 'g')
  return translatedValue.replace(regex, (matched: string) => {
    // for each match in the joined set, lookup the corresponding replacement from the original map
    return matchSet[matched]
  })
}

/**
 * @param terminology TerminologySettings to use
 * @param language language to use
 * @returns The translated string with all terminology tokens (e.g. [exclude]) parsed/removed and all the correct term replaced in the string
 */
const replaceTerms = (terminology: TerminologySettings, language: string) => (translatedValue: string) => {
  return entries(terminology)
    .reduce((acc, curr) => {
      const termValue: PhantomShareNames | ExercisePriceNames = curr[1]
      const termId: TerminologySettingType.PHANTOM_SHARE_NAMING | TerminologySettingType.EXERCISE_PRICE_NAMING =
        curr[0] as TerminologySettingType
      const defaultValue = TerminologySettingDefaults[termId] as unknown as PhantomShareNames | ExercisePriceNames
      const isTermValueDefault = termValue === defaultValue
      if (isTermValueDefault) {
        return acc
      }

      const matchSet = languageSets[language][termId][termValue]
      const matchSetJoin = languageSetsJoin[language][termId][termValue]

      if (!matchSet || !matchSetJoin) {
        return acc
      }

      const replaceMatchSetProps = { translatedValue: acc, matchSet, matchSetJoin }

      return IS_NEGATIVE_LOOKBEHIND_SUPPORTED // safari doesn't support negative lookbehinds in regex
        ? replaceMatchSet(replaceMatchSetProps)
        : replaceMatchSetSafari(replaceMatchSetProps)
    }, translatedValue)
    .replace(/\[exclude\]|\[\/exclude\]/g, '') // clean up exclude tags after all replacements have been made
}

export { replaceTerms }
