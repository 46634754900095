import type { Dispatch, SetStateAction } from 'react'
import { useRef, useState, useEffect, useCallback } from 'react'

/**
 * useSafeState is a lifecycle safe alternative to React.useState
 *
 * It should be used when a state update can be made after the component has been unmounted
 * This typically happens when setState is used in a setTimeout or Promise callback
 * The useEffect clean up method will set the isMounted boolean to false when the component is unmounted
 * The hook will verify if the component is mounted or not before updating the state
 */
function useSafeState<T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>]
function useSafeState<T>(intialState?: T): [T | undefined, Dispatch<SetStateAction<T | undefined>>]
function useSafeState<T>(initialState: T): [T, (state: T) => void] {
  const isMounted = useRef<boolean>()
  const [state, setState] = useState<T>(initialState)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const safeSetState = useCallback((state: T) => {
    if (!isMounted.current) {
      return
    }
    setState(state)
  }, [])

  return [state, safeSetState]
}

export { useSafeState }
