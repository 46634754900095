import { type CurrencyCode } from '@/camo/atoms'
import { COMPACT_THRESHOLD, Notation, Precision } from '@/camo/utils/currency/enums'
import { isDefined } from '@/camo/utils/is-defined'
import { FORMAT_LOCALE } from '@capdesk/utils'
import currencyCodes from 'currency-codes'
import { type InterpolationOptions } from 'i18next'

type FormatCurrencyOptions = Partial<{
  currency: CurrencyCode
  notation: Intl.NumberFormatOptions['notation']
  precision?: number
  digits?: number
  formatParams: any
  interpolationkey: any
}>

const formatCurrency = (
  value: number | string,
  {
    currency,
    notation = 'standard',
    precision,
    formatParams,
    interpolationkey,
    digits: digitsProp,
  }: FormatCurrencyOptions = {}
) => {
  const currencyToUse = formatParams?.[interpolationkey]?.currency ?? currency
  const digits = isDefined(currencyToUse) ? currencyCodes.code(currencyToUse)?.digits : 2
  const maximumFractionDigits = (() => {
    if (isDefined(precision)) {
      return precision
    }

    if (notation === 'compact') {
      return Precision.LOW
    }

    return digits ?? Precision.MEDIUM
  })()

  const minimumFractionDigits = (() => {
    if (isDefined(digitsProp)) {
      return digitsProp > maximumFractionDigits ? maximumFractionDigits : digitsProp
    }

    if (notation === 'compact') {
      return Precision.NONE
    }

    if (digits && maximumFractionDigits < digits) {
      return maximumFractionDigits
    }

    return digits
  })()

  const style = isDefined(currencyToUse) ? 'currency' : undefined

  return new Intl.NumberFormat(FORMAT_LOCALE, {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    currency: currencyToUse,
    notation,
  }).format(Number(value))
}

const isBelowCompactThreshold = (value: number) => value < COMPACT_THRESHOLD

const formatCurrencyWithThreshold = (
  value: number,
  options: (InterpolationOptions & Record<string, any>) | undefined
) => {
  const { notation, ...restOptions } = options ?? {}

  const intlNotation = notation === Notation.COMPACT_THRESHOLD ? Notation.COMPACT : notation

  if (notation === Notation.COMPACT_THRESHOLD && isBelowCompactThreshold(value)) {
    return formatCurrency(value, { ...restOptions, notation: Notation.STANDARD })
  }

  return formatCurrency(value, { ...options, notation: intlNotation })
}

export { formatCurrency, formatCurrencyWithThreshold, isBelowCompactThreshold }
