import { type ReactNode, createContext, useContext } from 'react'

interface InnerModalProviderProps {
  isInContext: boolean
  createButtonsPortal: (children: ReactNode) => ReactNode
}

const defaultContextValues = {
  isInContext: false,
  createButtonsPortal: (children: ReactNode) => children,
}

const ModalContext = createContext<InnerModalProviderProps>(defaultContextValues)

const useInnerModalContext = () => {
  return useContext<InnerModalProviderProps>(ModalContext)
}

const InnerModalProvider = ModalContext.Provider

export { useInnerModalContext, InnerModalProvider }
