import type { PropsWithChildren } from 'react'
import { createContext } from 'react'
import { type TypographyProps } from './types'

const TypographyContext = createContext<TypographyProps | undefined>(undefined)
type TypographyProviderProps = PropsWithChildren<{ childrenProps: Partial<TypographyProps<any>> }>

const TypographyProvider = ({ children, childrenProps }: TypographyProviderProps) => {
  const { Provider } = TypographyContext
  return <Provider value={childrenProps}>{children}</Provider>
}

export { TypographyProvider, TypographyContext }
export type { TypographyProviderProps }
