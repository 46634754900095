import { toaster } from 'baseui/toast'
import { overrides } from './overrides'
import { ToastKind } from './types'

type SupportedToastKinds = keyof Pick<typeof ToastKind, 'positive' | 'negative' | 'info'>

const showToast = (children: string, kind: SupportedToastKinds = ToastKind.positive) => {
  toaster[kind](children, {
    autoHideDuration: 5000,
    overrides,
  })
}
export { showToast }
export type { SupportedToastKinds }
