enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

enum ButtonKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  MINIMAL = 'minimal',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

enum CaretSize {
  SMALL = 20,
  MEDIUM = 24,
  LARGE = 28,
}

export { ButtonKind, ButtonSize, CaretSize }
