enum Spacing {
  NONE = 'none',
  XSMALL = 'xs',
  SMALL = 's',
  MEDIUM = 'm',
  LARGE = 'l',
  XLARGE = 'xl',
}

export { Spacing }
