import { type ButtonProps } from '@/camo/atoms/Button'
import { Spacing } from '@/camo/common/spacing'
import { useBreakpoint } from '@/camo/hooks/use-breakpoint'
import { Block, type BlockProps } from '@/camo/layouts/Block'
import { removeWrappingFragment } from '@/camo/utils/remove-wrapping-element'
import { Theme, useStyletron } from '@/camo/utils/theme'
import { type Responsive } from 'baseui/theme'
import type { PropsWithChildren } from 'react'
import { forwardRef, Children } from 'react'

type OmittedProps = 'display' | 'flex-direction'

type StackProps = Omit<BlockProps, OmittedProps> & {
  spacing?: Responsive<Spacing>
  direction?: Responsive<Direction>
  before?: boolean
  after?: boolean
} & {
  id?: string
  className?: string
  onClick?: ButtonProps['onClick']
  'aria-label'?: string
  'aria-selected'?: string
}

type Direction = 'column' | 'row' | 'column-reverse' | 'row-reverse'

const isHorizontal = (direction: Direction) => direction === 'row'

const StackSpacing = {
  [Spacing.NONE]: '0px',
  [Spacing.XSMALL]: Theme.sizing.scale100,
  [Spacing.SMALL]: Theme.sizing.scale300,
  [Spacing.MEDIUM]: Theme.sizing.scale600,
  [Spacing.LARGE]: Theme.sizing.scale800,
  [Spacing.XLARGE]: Theme.sizing.scale900,
}

type SpacerProps = PropsWithChildren<{
  direction: Responsive<Direction>
  spacing: Responsive<Spacing>
}>

const Spacer = ({ direction, spacing, children }: SpacerProps) => {
  const { getResponsiveValue } = useBreakpoint()
  const spacingValue = StackSpacing[getResponsiveValue(spacing)]

  const translateWidth = (responsiveDirection: Direction) => {
    return isHorizontal(responsiveDirection) ? spacingValue : 'inherit'
  }
  const translateHeight = (responsiveDirection: Direction) => {
    return isHorizontal(responsiveDirection) ? 'inherit' : spacingValue
  }

  const width = Array.isArray(direction) ? direction.map(translateWidth) : translateWidth(direction)
  const height = Array.isArray(direction) ? direction.map(translateHeight) : translateHeight(direction)

  return (
    <Block width={width} minWidth={width} height={height} minHeight={height}>
      {children}
    </Block>
  )
}

const Stack = forwardRef(
  (
    {
      children,
      before = false,
      after = false,
      spacing = Spacing.MEDIUM,
      direction = 'row',
      className,
      'aria-label': ariaLabel,
      'aria-selected': ariaSelected,
      ...props
    }: StackProps,
    ref
  ) => {
    const [css] = useStyletron()
    const { getResponsiveValue } = useBreakpoint()
    const spacings = Array.isArray(spacing) ? spacing.map((s) => StackSpacing[s]) : StackSpacing[spacing]
    const actualChildren = Children.toArray(children)
      .map(removeWrappingFragment)
      .flat()
      .filter((c) => c) // false or undefined elements (from JSX expressions) cause problems otherwise

    const childrenCount = Children.toArray(actualChildren).filter(Boolean).length
    return (
      <Block
        ref={ref}
        display="flex"
        {...props}
        flexDirection={direction}
        className={`${css({ gap: getResponsiveValue(spacings) })} ${className}`}
        aria-label={ariaLabel}
        aria-selected={ariaSelected}
      >
        {Children.map(actualChildren, (child, index) => (
          <>
            {before && index === 0 && <Spacer direction={direction} spacing={spacing} />}
            {child}
            {after && index === childrenCount - 1 && <Spacer direction={direction} spacing={spacing} />}
          </>
        ))}
      </Block>
    )
  }
)

export { Stack, Spacer, StackSpacing, type StackProps, type SpacerProps }
