type Entries<T> = Array<
  {
    [K in keyof T]-?: [K, T[K]]
  }[keyof T]
>

/**
 * Typesafe alternative to `Object.entries`
 * Without this Object.entries converts all keys to 'string' rather than keyof T
 */
const entries = <T extends object>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>
}

export { entries }
