import type { PropsWithChildren } from 'react'
import { createContext, useState, useCallback, useContext } from 'react'
import { ErrorModal, type ErrorModalProps } from 'src/components/common/ErrorModal'

type ShowErrorModalProps = Pick<ErrorModalProps, 'title' | 'body'> & {
  handleClose?: () => void
}

interface ErrorContextProps {
  showErrorModal: (props?: ShowErrorModalProps) => void
  closeErrorModal: () => void
}

const ErrorContext = createContext<ErrorContextProps>({ showErrorModal: () => {}, closeErrorModal: () => {} })

const useErrorContext = () => useContext<ErrorContextProps>(ErrorContext)

const ErrorProvider = ({ children }: PropsWithChildren) => {
  const [hasError, setHasError] = useState(false)
  const [props, setProps] = useState<ShowErrorModalProps>()

  const showErrorModal = useCallback((props?: ShowErrorModalProps) => {
    setProps(props)
    setHasError(true)
  }, [])

  const closeErrorModal = useCallback(() => {
    setHasError(false)
  }, [])

  return (
    <ErrorContext.Provider value={{ showErrorModal, closeErrorModal }}>
      {children}
      <ErrorModal
        isOpen={hasError}
        title={props?.title}
        body={props?.body}
        handleClose={props?.handleClose}
        autoFocus={false}
      />
    </ErrorContext.Provider>
  )
}

export { ErrorProvider, useErrorContext }
