import { Spinner, Text, TypographySize, type TextSize } from '@/camo/atoms'
import { useSafeState } from '@/camo/hooks'
import { VStack } from '@/camo/layouts'
import { NAVBAR_HEIGHT } from '@/camo/molecules'
import { Block, type Responsive } from 'baseui/block'
import { useEffect } from 'react'

type LoadingProps = {
  delayed?: boolean
  fullHeight?: boolean
  hasLayout?: boolean
  textSize?: TextSize
  backgroundColor?: Responsive<string>
} & XOR<{ hideText?: true; text?: string }, { hideText?: false; text: string }>

const PADDING = '64px'
const FOOTER = '76px'

const Loading = ({
  delayed = false,
  fullHeight = true,
  hasLayout = false,
  hideText = false,
  textSize = TypographySize.SMALL,
  text,
  backgroundColor = 'white',
}: LoadingProps) => {
  /**
   * In some cases delaying to show the loader might be good,
   * so we don't get a flickering effect
   */
  const [showSpinner, setShowSpinner] = useSafeState(!delayed)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(true)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [setShowSpinner])

  const getHeight = () => {
    if (fullHeight) {
      return hasLayout ? `calc(100vh - ${NAVBAR_HEIGHT} - ${FOOTER} - ${PADDING})` : '100vh'
    }
    return '100%'
  }

  return (
    <Block
      backgroundColor={backgroundColor}
      width="100%"
      height={getHeight()}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {showSpinner && (
        <VStack alignItems="center">
          <Spinner />
          {!hideText && <Text size={textSize}>{text}</Text>}
        </VStack>
      )}
    </Block>
  )
}

export { Loading }
export type { LoadingProps }
