import { TypographyColor } from '@/camo/atoms/Typography'
import { Text } from '@/camo/atoms/Typography/Text'
import { Block } from '@/camo/layouts/Block'
import { useStyletron } from '@/camo/utils/theme'
import { styled } from 'baseui'
import type { Property } from 'csstype'
import { type PropsWithChildren } from 'react'

type DividerProps = PropsWithChildren<{
  color?: string | TypographyColor
  padding?: Property.Padding
}>

const HorizontalLine = styled<'span', { color: string }>('span', ({ color }) => ({
  position: 'absolute',
  background: color,
  height: '1px',
  width: '100%',
  top: 'calc(50% + 1px)',
}))

const Divider = ({ children, color, padding }: DividerProps) => {
  const [css, theme] = useStyletron()
  return (
    <Block width="100%" display="flex" alignItems="center" justifyContent="center" position="relative">
      <HorizontalLine color={color ?? theme.colors.borderOpaque} />

      <Text
        className={css({
          padding: padding ?? '0 1em',
          position: 'relative',
          backgroundColor: children ? theme.colors.backgroundPrimary : 'transparent',
        })}
        color={TypographyColor.GRAY_DARK}
      >
        {children}
      </Text>
    </Block>
  )
}

export { Divider, type DividerProps }
