const replaceMatchSetSafari = ({
  translatedValue,
  matchSet,
  matchSetJoin,
}: {
  translatedValue: string
  matchSet: Index<string>
  matchSetJoin: string
}) => {
  const regex = new RegExp(`\\b(${matchSetJoin})\\b`, 'g')
  const matchFinder = (matched: string) => {
    // for each match in the joined set, lookup the corresponding replacement from the original map
    return matchSet[matched]
  }

  return translatedValue
    .split('[exclude]')
    .map((splitItem, index) => {
      let result = splitItem
      if (index === 0 && !translatedValue.startsWith('[exclude]')) {
        // this is the text before the first exclude and it needs to be replaced
        result = result.replace(regex, matchFinder)
      }
      if (splitItem.includes('[/exclude]')) {
        const subsplit = splitItem.split('[/exclude]')
        result = subsplit
          .map((subsplitItem, index) => {
            if (index === 0) {
              // skip the first item as it is the exclude tag
              // this assumes theres only 1 exclude closing tag (i.e. no nested exclude tags)
              return subsplitItem
            }
            return subsplitItem.replace(regex, matchFinder)
          })
          .join('[/exclude]')
      }
      return result
    })
    .join('[exclude]')
}

export { replaceMatchSetSafari }
