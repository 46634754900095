import { LAYERS_CONTAINER_ID } from '@/camo/hooks/use-click-outside'
import { useLocalStorageToasts } from '@/camo/hooks/use-local-storage-toasts'
import { Theme } from '@/camo/utils/theme'
import { BaseProvider } from 'baseui'
import { PLACEMENT, ToasterContainer } from 'baseui/toast'
import type React from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'

const engine = new Styletron({ prefix: 'c' })

interface CamoProps {
  children?: React.ReactNode
  toastStorageKey?: string
}

const CamoProvider = ({ children, toastStorageKey = 'com.capdesk.toast' }: CamoProps) => {
  useLocalStorageToasts(toastStorageKey)
  return (
    <StyletronProvider value={engine}>
      <BaseProvider overrides={{ LayersContainer: { props: { id: LAYERS_CONTAINER_ID } } }} theme={Theme} zIndex={10}>
        <ToasterContainer
          placement={PLACEMENT.topRight}
          overrides={{
            Root: {
              style: {
                zIndex: 10,
              },
            },
          }}
        />
        {children}
      </BaseProvider>
    </StyletronProvider>
  )
}

export { CamoProvider }
