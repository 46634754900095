enum Precision {
  NONE = 0,
  LOW = 2,
  MEDIUM = 7,
  HIGH = 15,
}

enum Notation {
  // Default notation values supported by Intl.NumberFormat
  STANDARD = 'standard',
  COMPACT = 'compact',
  SCIENTITIC = 'scientific',
  ENGINEERING = 'engineering',

  // Custom values
  COMPACT_THRESHOLD = 'compact-threshold',
}

const COMPACT_THRESHOLD = 1_000_000

export { Precision, Notation, COMPACT_THRESHOLD }
