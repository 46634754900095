import { forwardRef } from 'react'
import { Stack, type StackProps } from './Stack'

type OmittedProps = 'direction'
type HStackProps = Omit<StackProps, OmittedProps>

const HStack = forwardRef(({ ...props }: HStackProps, ref) => {
  return <Stack ref={ref} direction="row" {...props} />
})

export { HStack, type HStackProps }
