import { PLACEMENT as TooltipPlacement } from 'baseui/tooltip'
import { TriggerType } from './enums'

const HOVER_TOOLTIP_SETTINGS = {
  placement: TooltipPlacement.bottom,
  triggerType: TriggerType.HOVER,
  showArrow: true,
  popoverMargin: 2,
}

export { HOVER_TOOLTIP_SETTINGS }
