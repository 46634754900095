import { ButtonKind, Divider, Modal, ModalBody, ModalFooterButtons, ModalHeader, type ModalProps } from '@capdesk/camo'
import type React from 'react'
import { useTranslation } from 'react-i18next'

type ErrorModalProps = ModalProps & {
  isOpen?: boolean
  title?: string
  body?: string | React.ReactNode
  handleClose?: () => void
}

const reloadPage = () => {
  window.location.reload()
}

const ErrorModal = ({ title, body, handleClose = reloadPage, ...props }: ErrorModalProps) => {
  const { t } = useTranslation('common')
  return (
    <Modal onClose={handleClose} closeable={false} {...props}>
      <ModalHeader>{title ?? t('error.title')}</ModalHeader>
      <ModalBody>{body ?? t('error.message')}</ModalBody>
      <Divider />
      <ModalFooterButtons
        actions={[
          {
            onClick: handleClose,
            kind: ButtonKind.PRIMARY,
            label: t('okay'),
          },
        ]}
      />
    </Modal>
  )
}

export { ErrorModal }
export type { ErrorModalProps }
