import { CamoProvider } from '@capdesk/camo'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { lazy, StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { IntercomProvider } from 'react-use-intercom'
import { TerminologyProvider } from 'src/i18n/terminology/TerminologyProvider'
import { Loading } from 'src/pages/Loading'
import { INTERCOM_APP_ID, REACT_QUERY_DEVTOOLS_ENABLED } from 'src/variables'
import './App.css'
import './analytics/fullstory'
import './polyfill'

// Reload the page if we hit a chunk that doesn't exist on the server any more
window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})

const Routes = lazy(async () => await import('./Routes'))

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
})

// Debug log to make it easier to define which app we're on
// eslint-disable-next-line no-console
console.log('%cWelcome to Capdesk 🚀', 'font-weight: bold')

root.render(
  <StrictMode>
    <TerminologyProvider>
      <HelmetProvider>
        <Helmet defaultTitle="Capdesk" titleTemplate="%s | Capdesk" />
        <CamoProvider>
          <IntercomProvider autoBoot={Boolean(INTERCOM_APP_ID)} appId={INTERCOM_APP_ID ?? ''}>
            <Suspense fallback={<Loading />}>
              <QueryClientProvider client={queryClient}>
                <Routes />
                {REACT_QUERY_DEVTOOLS_ENABLED && <ReactQueryDevtools initialIsOpen={false} />}
              </QueryClientProvider>
            </Suspense>
          </IntercomProvider>
        </CamoProvider>
      </HelmetProvider>
    </TerminologyProvider>
  </StrictMode>
)
