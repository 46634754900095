import { useStyletron } from '@/camo/utils/theme'
import { omit } from '@capdesk/utils'
import * as React from 'react'
import { type IconBaseProps } from 'react-icons'
import { useHover } from 'react-use'
import { Icon } from './Icon'
import { IconColor } from './enums'
import { type IconProps, type WithIconOptions } from './types'

const PARENT_ONLY_PROPS = ['aria-label', 'role'] as const

const withIcon = (
  SpecificIcon: React.FC<IconBaseProps>,
  { title = SpecificIcon.displayName }: WithIconOptions = {}
): React.FC<IconProps> => {
  function WithIcon({
    color: colorProp = IconColor.DEFAULT,
    hoverColor: hoverColorProp,
    active,
    activable = false,
    title: titleProp = title,
    hovering: hoveringProp = false,
    ...props
  }: IconProps) {
    const [, theme] = useStyletron()

    const IconColors: Record<string, string> = {
      [IconColor.DEFAULT]: theme.colors.iconDefaultFillColor,
      [IconColor.PRIMARY]: theme.colors.primary,
      [IconColor.POSITIVE]: theme.colors.limeDarkest,
      [IconColor.NEGATIVE]: theme.colors.redDarker,
      [IconColor.ACCENT]: theme.colors.accent,
      [IconColor.WHITE]: theme.colors.white,
      [IconColor.GRAY_DARK]: theme.colors.grayDark,
    }

    const childrenProps = omit(props, PARENT_ONLY_PROPS)

    const color = (colorProp in IconColors ? IconColors[colorProp] : colorProp) ?? theme.colors.iconDefaultFillColor
    const hoverColor = hoverColorProp && hoverColorProp in IconColors ? IconColors[hoverColorProp] : color
    const activeColor = theme.colors.iconActiveFillColor

    const [hoverable] = useHover((hoveringArg) => {
      const hovering = hoveringProp || hoveringArg

      return (
        <Icon {...props}>
          <SpecificIcon
            aria-label={titleProp}
            {...{ ...childrenProps, 'aria-hidden': undefined }}
            title={titleProp}
            color={active && activable ? activeColor : hovering ? hoverColor : color}
          />
        </Icon>
      )
    })

    return hoverable
  }
  WithIcon.displayName = title
  return React.forwardRef((props, _) => <WithIcon {...props} />)
}

export { withIcon }
