import plur from 'pluralize'

function configurePluralize(opts: { unnacountableRules?: string[] }) {
  const { unnacountableRules = [] } = opts
  for (const rule of unnacountableRules) {
    plur.addUncountableRule(rule)
  }
}

/**
 * Pluralize a word based on the count
 *
 * This is a rule based function that will only work for English — i.e. It will repalce word ending in -y with -ies
 */
function pluralize(opts: Partial<{ word: string; count: number }>) {
  const { word, count = 0 } = opts
  if (!word) {
    return
  }
  return plur(word, count)
}

export { pluralize, configurePluralize }
