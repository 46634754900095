import type React from 'react'
import { type PropsWithChildren } from 'react'

type ConditionalWrapperProps = PropsWithChildren<{
  condition: boolean
  wrapper?: (children: React.ReactNode) => React.ReactElement
}>

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition && wrapper ? wrapper(children) : <>{children}</>

export { ConditionalWrapper }
export type { ConditionalWrapperProps }
