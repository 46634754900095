import * as Sentry from '@sentry/react'

class MissingTranslationKeyError extends Error {
  name = 'MissingTranslationKeyError'
}

const missingKeyHandler: (
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string,
  updateMissing: boolean,
  options: any
) => void = (_, ns, key) => {
  const message = `Missing translation key ${ns}:${key}`
  if (process.env.NODE_ENV !== 'test') {
    console.warn(message)
  }

  Sentry.captureException(new MissingTranslationKeyError(message))
}

export { missingKeyHandler }
