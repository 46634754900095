import { useState, useEffect } from 'react'
import { usePrevious, useTimeoutFn } from 'react-use'

function useCanBeUnmounted(props: { isOpen: boolean }) {
  const { isOpen } = props

  const [isClosing, setIsClosing] = useState(false)
  const [canBeUnmounted, setCanBeUnmounted] = useState<boolean>(true)
  const wasOpen = usePrevious(isOpen)

  useTimeoutFn(
    () => {
      if (!isClosing) {
        return
      }

      setIsClosing(false)
      setCanBeUnmounted(true)
    },
    // This is the transition delay, if we unmount before that we lose the transition
    isClosing ? 500 : undefined
  )

  useEffect(() => {
    // is closing
    if (wasOpen && !isOpen) {
      setIsClosing(true)
    }

    // is opening
    if (!wasOpen && isOpen) {
      setIsClosing(false)
      setCanBeUnmounted(false)
    }

    if (isOpen && !isClosing) {
      setCanBeUnmounted(false)
    }
  }, [isClosing, isOpen, wasOpen])

  return { canBeUnmounted }
}

export { useCanBeUnmounted }
