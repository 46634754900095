import { keys } from 'src/utilities/keys'
import templateSelector from './template_selector.json'

const resources = {
  templateSelector,
}

const namespaces = keys(resources)

export default resources
export { namespaces }
