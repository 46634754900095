// See .env.example for details on the variables
const ENV = process.env.REACT_APP_ENV ?? ''
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
const FEATURE_FLAG_CLIENT_ID = process.env.REACT_APP_FEATURE_FLAG_CLIENT_ID
const ENABLE_ALL_FEATURE_FLAGS = process.env.REACT_APP_ENABLE_ALL_FEATURE_FLAGS === 'true'
const FORM_DEBUG = process.env.REACT_APP_FORM_DEBUG === 'true'
const REACT_QUERY_DEVTOOLS_ENABLED = process.env.REACT_APP_REACT_QUERY_DEVTOOLS === 'true'

// Datadog configuration
const DD_APPLICATION_ID = process.env.REACT_APP_DD_APPLICATION_ID ?? ''
const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN ?? ''
const DD_APP_SERVICE = process.env.REACT_APP_DD_APP_SERVICE ?? ''
const DD_SITE = process.env.REACT_APP_DD_SITE ?? ''

const IS_LOCAL = !/(prod|load|qa|dummy)/i.test(ENV)
const IS_DUMMY_OR_QA = /(qa|dummy)/i.test(ENV)
const IS_NON_PRODUCTION_ENV = !/(prod)/i.test(ENV)
const IS_PRODUCTION_ENV = /(prod)/i.test(ENV)

const DISABLE_UNRELEASED_ROUTES = process.env.REACT_APP_DISABLE_UNRELEASED_ROUTES === 'true'
const IS_EXPERIMENTAL_ROUTING_ENABLED = IS_LOCAL && !DISABLE_UNRELEASED_ROUTES
const IS_PRE_RELEASE_ROUTING_ENABLED = (IS_LOCAL || IS_DUMMY_OR_QA) && !DISABLE_UNRELEASED_ROUTES

export {
  ENV,
  FORM_DEBUG,
  SENTRY_DSN,
  FULLSTORY_ORG_ID,
  INTERCOM_APP_ID,
  FEATURE_FLAG_CLIENT_ID,
  ENABLE_ALL_FEATURE_FLAGS,
  IS_LOCAL,
  IS_DUMMY_OR_QA,
  IS_NON_PRODUCTION_ENV,
  IS_PRODUCTION_ENV,
  DISABLE_UNRELEASED_ROUTES,
  IS_EXPERIMENTAL_ROUTING_ENABLED,
  IS_PRE_RELEASE_ROUTING_ENABLED,
  REACT_QUERY_DEVTOOLS_ENABLED,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_APP_SERVICE,
  DD_SITE,
}
