import { Alert, Check, InfoCircled } from '@/camo/icons/icons'
import { Block } from '@/camo/layouts/Block'
import { HStack } from '@/camo/layouts/Stack'
import { type SupportedToastKinds } from '@/camo/molecules/Toast/show-toast'
import { Theme, styled, useStyletron } from '@/camo/utils/theme'
import { type SharedStylePropsArg } from 'baseui/toast'
import { type PropsWithChildren } from 'react'
import { IconContainer } from './IconContainer'
import { ToastKind } from './types'

const ToastText: React.FC<PropsWithChildren> = styled(Block, {
  flex: 1,
})

const ToastIcons = {
  [ToastKind.positive]: <Check size="24px" color={Theme.colors.toastIconPositive} />,
  [ToastKind.negative]: <Alert size="24px" color={Theme.colors.toastIconNegative} />,
  [ToastKind.info]: <InfoCircled size="24px" color={Theme.colors.toastIconInfo} />,
}

const InnerContainer = ({
  children,
  $kind: kind,
}: PropsWithChildren<Omit<SharedStylePropsArg, 'kind'> & { $kind: SupportedToastKinds }>) => {
  const [, theme] = useStyletron()
  const icon = kind ? ToastIcons[kind] : undefined
  return (
    <HStack alignItems="center" flex="1">
      {icon ? <IconContainer kind={kind}>{icon}</IconContainer> : undefined}
      <Block padding={`${theme.sizing.scale100} 0`}>
        <ToastText>{children}</ToastText>
      </Block>
    </HStack>
  )
}

export { InnerContainer }
