import { TypographySize, TypographyWeight } from '@/camo/atoms/Typography/enums'
import { styled } from '@/camo/utils'
import { LabelXSmall, LabelMedium, LabelSmall, LabelLarge } from 'baseui/typography'
import { createTypographyComponent } from './common'

const LabelXXSmall = styled(LabelXSmall, { fontSize: '10px !important', lineHeight: '1.1 !important', fontWeight: 500 })

/**
 * A Text element.
 *
 * Rendered as a `<div />` these should be used for padding-less text elements
 */
const Text = createTypographyComponent(
  {
    [TypographySize.LARGE]: LabelLarge,
    [TypographySize.MEDIUM]: LabelMedium,
    [TypographySize.SMALL]: LabelSmall,
    [TypographySize.XSMALL]: LabelXSmall,
    [TypographySize.XXSMALL]: LabelXXSmall,
  },
  { type: 'Label' }
)

const BoldText = createTypographyComponent(
  {
    [TypographySize.LARGE]: LabelLarge,
    [TypographySize.MEDIUM]: LabelMedium,
    [TypographySize.SMALL]: LabelSmall,
    [TypographySize.XSMALL]: LabelXSmall,
    [TypographySize.XXSMALL]: LabelXXSmall,
  },
  { type: 'Label', weight: TypographyWeight.SEMI_BOLD }
)

type TextSize =
  | TypographySize.LARGE
  | TypographySize.MEDIUM
  | TypographySize.SMALL
  | TypographySize.XSMALL
  | TypographySize.XXSMALL

export { Text, BoldText }
export type { TextSize }
