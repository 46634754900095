import { Block, Notification, NotificationKind } from '@capdesk/camo'
import * as Sentry from '@sentry/react'
import React from 'react'
import { ApiErrors } from 'src/components/common/ApiErrors/ApiErrors'
import { useErrorContext } from 'src/contexts/ErrorProvider'
import { type ApiError } from 'src/hooks/use-api'
import { ENV } from 'src/variables'

interface HandleClientErrorArgs {
  error: Error | string | undefined
  title?: string
  body?: string | React.ReactNode
  handleClose?: () => void
  eventHint?: Sentry.EventHint
  notifyUser?: boolean
}

const useErrorHandler = () => {
  const { showErrorModal, closeErrorModal } = useErrorContext()

  const handleClientError = React.useCallback(
    (
      { error, title, body, handleClose, eventHint, notifyUser = true }: HandleClientErrorArgs = { error: undefined }
    ) => {
      if (error === undefined) {
        return
      }

      if (typeof error === 'string') {
        error = new Error(error)
      }
      Sentry.captureException(error, eventHint)
      if (notifyUser) {
        showErrorModal({ title, body, handleClose })
      }
      if (ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    },
    [showErrorModal]
  )

  const handleServerError = React.useCallback(() => {
    showErrorModal()
  }, [showErrorModal])

  const handleApiError = React.useCallback(
    (title: string, apiError: ApiError) => {
      showErrorModal({
        title,
        body: (
          <Notification kind={NotificationKind.NEGATIVE} hideBorder>
            <Block style={{ wordBreak: 'break-word' }}>
              <ApiErrors apiError={apiError} />
            </Block>
          </Notification>
        ),
        handleClose: closeErrorModal,
      })
    },
    [showErrorModal, closeErrorModal]
  )

  return { handleClientError, handleServerError, handleApiError }
}

export { useErrorHandler }
export type { HandleClientErrorArgs }
