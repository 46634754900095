import { Block } from '@/camo/layouts'
import { Icon as BaseIcon } from 'baseui/icon'
import { IconSize } from './enums'
import { type IconProps } from './types'

const Icon = ({ size = IconSize.MEDIUM, ...props }: IconProps) => {
  return (
    <Block height="100%" width={size} display="flex" justifyContent="center" alignItems="center">
      <BaseIcon size={size} {...props} />
    </Block>
  )
}

export { Icon }
