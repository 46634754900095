export const convertDateToUTC = (date: Date | string) => {
  const d = date instanceof Date ? date : new Date(date)

  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  )
}
