enum IconSize {
  XXSMALL = '10px',
  XSMALL = '12px',
  SMALL = '16px',
  MEDIUM = '20px',
  LARGE = '30px',
  XLARGE = '36px',
  XXLARGE = '60px',
}

enum IconColor {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  ACCENT = 'accent',
  WHITE = 'white',
  GRAY_DARK = 'gray_dark',
  INHERIT = 'inherit',
}

export { IconSize, IconColor }
