import { expand } from 'inline-style-expand-shorthand'
import { type StyleObject } from 'styletron-react'

/**
 * Expands style shorthand.
 *
 * WARNING: This makes a lot of assumptions about your CSS. If the CSS
 * is tricky you should double check the resulting styles to ensure
 * they are performing how you want.
 */
const expandShorthand = (shorthand: StyleObject): StyleObject => expand(shorthand)

export { expandShorthand }
