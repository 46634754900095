import { useBreakpoint } from '@/camo/hooks/use-breakpoint'
import { expandShorthand } from '@/camo/utils/expand-shorthand'
import { type PopoverOverrides } from 'baseui/popover'
import { PLACEMENT as TooltipPlacement, StatefulTooltip as BaseTooltip } from 'baseui/tooltip'
import { TriggerType } from './enums'
import { type TooltipProps } from './types'

const overrides = (maxWidth = '420px'): PopoverOverrides => ({
  Inner: {
    style: ({ $theme }) => ({
      fontSize: $theme.typography.ParagraphSmall.fontSize,
      fontWeight: $theme.typography.ParagraphSmall.fontWeight,
      ...expandShorthand({ borderRadius: 0, padding: $theme.sizing.scale600 }),
    }),
  },
  Body: {
    props: { role: 'tooltip' },
    style: ({ $theme }) => ({
      maxWidth,
      ...expandShorthand({ borderRadius: $theme.borders.surfaceBorderRadius }),
    }),
  },
})

const Tooltip = ({
  showArrow = false,
  popoverMargin = 10,
  triggerType = TriggerType.HOVER,
  placement = TooltipPlacement.bottomLeft,
  width = ['304px', '420px'],
  ...props
}: TooltipProps) => {
  const { getResponsiveValue } = useBreakpoint()
  return (
    <BaseTooltip
      accessibilityType="tooltip"
      showArrow={showArrow}
      popoverMargin={popoverMargin}
      triggerType={triggerType}
      placement={placement}
      {...props}
      overrides={overrides(getResponsiveValue(width))}
    />
  )
}

export { Tooltip, TooltipPlacement }
