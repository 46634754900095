import { Block } from '@/camo/layouts/Block'
import { type SupportedToastKinds } from '@/camo/molecules/Toast/show-toast'
import { useStyletron } from '@/camo/utils/theme'
import { type PropsWithChildren } from 'react'
import { ToastKind } from './types'

type IconContainerProps = PropsWithChildren<{ kind?: SupportedToastKinds }>

const IconContainer = ({ children, kind = ToastKind.positive }: IconContainerProps) => {
  const [, theme] = useStyletron()
  const backgroundColors = {
    [ToastKind.positive]: theme.colors.toastIconPositiveBackground,
    [ToastKind.negative]: theme.colors.toastIconNegativeBackground,
    [ToastKind.info]: theme.colors.toastIconInfoBackground,
  }
  return (
    <Block
      width={theme.sizing.scale1200}
      height="100%"
      display="flex"
      flex="none"
      justifyContent="center"
      alignItems="center"
      backgroundColor={backgroundColors[kind]}
      style={{
        borderTopLeftRadius: theme.borders.surfaceBorderRadius,
        borderBottomLeftRadius: theme.borders.surfaceBorderRadius,
      }}
    >
      {children}
    </Block>
  )
}

export { IconContainer }
